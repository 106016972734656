import React, { useState, useEffect } from 'react'
import Header from "../../components/header"
import HeaderMenu from "../../components/header-menu"
import { Link } from 'gatsby'
import { getUser} from "../../services/auth"
import Footer from "../../components/footer"
import { navigate } from "gatsby"
import classNames from "classnames"
import { getItem} from "../../services/session"

const Informes = () => {

  const [visites, setVisites] = useState([]); 
  const [isLoading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(5)
  const [num, setNum] = useState(5)
  
  
  const handleBack = (e) => {
    e.preventDefault();
    navigate("/veterinaris/menuVisites")    
  }
  const handlePrev = (e) => {
    e.preventDefault();
    let page2=page-num;
    loadResults(page2,num)
  }


  const handleNext = (e) => {
    e.preventDefault();
    let page2=page+num;
    loadResults(page2,num)
  }

  const loadResults = (_page,_count) =>{
    setLoading(true)
    let granja=getItem("userGranjaTecGranja");
   
     fetch("/auth/getVisites/"+granja.granjaSelected+"?page="+_page+"&count="+_count ,{ 
      method: 'get', 
      headers: new Headers({
          'Authorization':   getUser().token
      })
    })
    .then(response => response.json())        
    .then(_data => {
      setVisites(_data.visites)
      setCount(_data.count)
      setPage(_page)
      setCount(_count)
      setLoading(false)
    })
  }
  useEffect( () => {
    if (!getUser() || getUser().nivell.veterinari!==1) {
      navigate("../../mainMenu") 
    }
    loadResults(0,5)
  },[])
  

  return (
    <>
        <div className="granjatec-wrapper">
          <Header/>
          <HeaderMenu />
          <div className="main-container">
              <section className="section heading-opt">
                <div className="heading-opt-title">
                  <h2 className="h5">Informes</h2>
                </div>
              </section>
              <section className="section form-opt destete-engreix">
              <div className="steps">
                <div className="steps-panel">
                  <div className="steps-panel-scroller">
                    {visites.map((visita,i) => {
                      return (
                        <div className="informe-thumb">
                          <Link   to={`/veterinaris/detall/${visita.id}`}>
                            <div className="informe-thumb-date"><span>{visita.data.slice(0, 10)}</span></div>
                            <div className="informe-thumb-title">{visita.titol}</div>
                            <div className="informe-thumb-user"><strong>Veterinari:</strong> {visita.nom_usuari} ({visita.id})</div>
                            <div className="informe-thumb-files"><strong>Fitxers:</strong> { (visita.pdf1 || visita.pdf2 || visita.foto1 || visita.foto2 ||visita.foto3)?(
                                <strong className="success">Si</strong>)
                                : <strong className="danger">No</strong>
                            }</div>
                            <div className="informe-thumb-link"><span>Veure informe</span></div>
                          </Link>
                        </div>
                        )
                    })}
                 
                </div>
                <div className="steps-nav">
                  <button className="btn-secondary btn--xs steps-nav-init" onClick={handleBack}>Tornar</button>
                    { page >= num ?
                    
                    <button onClick={handlePrev} className={classNames('steps-nav-btn', 'steps-nav-btn-prev')}>
                      <span>Prev</span>
                    </button>
                    :<></>
                     }
                  { page <= count ?
                    <button onClick={handleNext} className={classNames('steps-nav-btn steps-nav-btn-next')}>
                      <span>Next</span>
                    </button>
                    :
                    <></>
                  }
                </div>
                </div>
              
          </div>
         
          </section>
          { isLoading && (
              <div className="overlay-loading"></div>
              )}
        </div>
        <Footer/>
        </div>
</>
  )
}
export default Informes;
